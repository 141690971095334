<template>
  <div class="record-item">

    <div v-if="type === 'text'" class="record-item__text">
      {{msg.text}}
    </div>

    <template v-else-if="type === 'image'">
      <a-image :src="msg.imageUrl" width="250px"></a-image>
    </template>

    <template v-else-if="type === 'link'">
      <msg-link class="mh0" :msg='{title: msg.title, subTitle: msg.description, img: msg.imageUrl}'
        @click="openLinkUrl(msg.linkUrl)"></msg-link>
    </template>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import { Image } from "ant-design-vue";

import MsgLink from "@/components/MsgLink";

export default defineComponent({
  name: "RecordItem",

  props: {
    msg: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    AImage: Image,
    MsgLink,
  },

  setup(props) {
    const type = computed(() => {
      return props.msg.contentType;
    });

    function openLinkUrl(linkUrl) {
      window.open(linkUrl);
    }

    return {
      type,
      openLinkUrl,
    };
  },
});
</script>
<style lang='less' scoped>
.record-item {
  .ml8();

  &__text {
    padding: 6px @spacing-3x;
    color: @gray-darker;
    word-break: break-all;
    background-color: @bg-normal;
    .border-radius();
  }
}

.send {
  .record-item {
    margin-right: 8px;

    &__text {
      background-color: @blue-5;
      color: @white;
    }
  }

  .msg-link {
    .border();
    background-color: @bg;
  }
}
</style>